import { waitAuth } from "~/utils/middleware";

import { log } from "~/composables/log";
import { waitForPB } from "~/composables/pb";
import { hasAuthorization, RoleRules } from "~/composables/team";

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth();
    await waitForPB();

    if (!hasAuthorization(RoleRules.ObjectStorage)) {
        log("❌ Cannot access object storage");
        return navigateTo("/");
    }

    log("✅ Can access object storage");
});
